var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"coupon-card-container"},[(_vm.item)?_c('div',{staticClass:"left-side p-1",style:({ background: _vm.item ? `#${_vm.brandColor(_vm.item)}` : 'none' })},[_c('div',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.item.brandTitle))])]):_vm._e(),_c('div',{staticClass:"center-side",on:{"click":function($event){_vm.onClickShowDetail ? _vm.onClickShowDetail(_vm.item) : () => {}}}},[_c('div',{staticClass:"coupon-title"},[_c('b',[_vm._v(_vm._s(_vm.item.title))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.item.promotionText))])]),_c('div',{staticClass:"coupon-content"},[_c('small',[_vm._v("ใช้ได้ถึง "+_vm._s(_vm.$getFullDate(_vm.item.couponStartDate))+" - "+_vm._s(_vm.item.couponLifetimeType === "datetime" ? _vm.$getFullDate(_vm.item.couponEndDate) : _vm.$getFullDate(_vm.item.endDate)))])])]),_c('div',{staticClass:"right-side"},[(
        _vm.item && _vm.item.active === undefined && _vm.item.couponRedeemRemaining === 0
      )?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":""},on:{"click":function($event){return _vm.onClick(_vm.item)}}},[_vm._v(" หมดแล้ว ")]):_c('div',[(
          (_vm.item &&
            _vm.item.active === undefined &&
            _vm.item.couponRedeemMaxUser > 0 &&
            _vm.item.couponRedeemRemainingUser > 0) ||
          (_vm.item &&
            _vm.item.active === undefined &&
            _vm.item.couponRedeemMaxUser === 0)
        )?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.onClick(_vm.item)}}},[_vm._v(" เก็บคูปอง ")]):_vm._e(),(
          _vm.item &&
          _vm.item.active === undefined &&
          _vm.item.couponRedeemMaxUser > 0 &&
          _vm.item.couponRedeemRemainingUser === 0 &&
          _vm.item.couponRedeemRemaining > 0
        )?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":""},on:{"click":function($event){return _vm.onClick(_vm.item)}}},[_vm._v(" เก็บแล้ว ")]):_vm._e(),(_vm.item && _vm.item.active === true)?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.onClick(_vm.item)}}},[_vm._v(" ใช้ ")]):_vm._e(),(_vm.item && _vm.item.active === false && _vm.item.used)?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":""},on:{"click":function($event){return _vm.onClick(_vm.item)}}},[_vm._v(" ใช้แล้ว ")]):_vm._e(),(_vm.item && _vm.item.active === false && _vm.item.expired && !_vm.item.used)?_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":""},on:{"click":function($event){return _vm.onClick(_vm.item)}}},[_vm._v(" หมดอายุ ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }