<template>
  <div class="coupon-card-container">
    <div
      v-if="item"
      class="left-side p-1"
      :style="{ background: item ? `#${brandColor(item)}` : 'none' }"
    >
      <div class="text-capitalize">{{ item.brandTitle }}</div>
    </div>
    <div
      class="center-side"
      @click="onClickShowDetail ? onClickShowDetail(item) : () => {}"
    >
      <div class="coupon-title">
        <b>{{ item.title }}</b>
        <br />
        <small>{{ item.promotionText }}</small>
      </div>
      <div class="coupon-content">
        <small
          >ใช้ได้ถึง {{ $getFullDate(item.couponStartDate) }} -
          {{
            item.couponLifetimeType === "datetime"
              ? $getFullDate(item.couponEndDate)
              : $getFullDate(item.endDate)
          }}</small
        >
      </div>
    </div>
    <div class="right-side">
      <button
        v-if="
          item && item.active === undefined && item.couponRedeemRemaining === 0
        "
        disabled
        class="btn btn-primary"
        @click="onClick(item)"
      >
        หมดแล้ว
      </button>
      <div v-else>
        <button
          v-if="
            (item &&
              item.active === undefined &&
              item.couponRedeemMaxUser > 0 &&
              item.couponRedeemRemainingUser > 0) ||
            (item &&
              item.active === undefined &&
              item.couponRedeemMaxUser === 0)
          "
          class="btn btn-primary"
          @click="onClick(item)"
        >
          เก็บคูปอง
        </button>
        <button
          v-if="
            item &&
            item.active === undefined &&
            item.couponRedeemMaxUser > 0 &&
            item.couponRedeemRemainingUser === 0 &&
            item.couponRedeemRemaining > 0
          "
          disabled
          class="btn btn-primary"
          @click="onClick(item)"
        >
          เก็บแล้ว
        </button>
        <button
          v-if="item && item.active === true"
          class="btn btn-primary"
          @click="onClick(item)"
        >
          ใช้
        </button>
        <button
          v-if="item && item.active === false && item.used"
          disabled
          class="btn btn-primary"
          @click="onClick(item)"
        >
          ใช้แล้ว
        </button>
        <button
          v-if="item && item.active === false && item.expired && !item.used"
          disabled
          class="btn btn-primary"
          @click="onClick(item)"
        >
          หมดอายุ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    brandColor(item) {
      const { brandTitle } = item;
      switch (brandTitle.toLowerCase()) {
        case "crystal":
          return "00AEEF";
        case "oishi":
          return "6cc247";
        case "est":
          return "002bd7";
        case "ranger":
          return "FF0000";
        case "v-boost":
          return "F36D0E";
        case "100plus":
          return "FF00FF";
        case "jubjai":
          return "CC0000";
        case "vboost":
          return "FE8F33";
        case "powerplus":
          return "ffcc33";
        default:
          return "4d4d4d";
      }
    },
  },
  props: {
    item: {
      type: Object,
    },
    onClick: {
      type: Function,
    },
    onClickShowDetail: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scope>
$blue: #189ad6;
$light-gray: #ced3da;
$white: #fff;
$radius: 6px;

.coupon-card-container {
  align-content: center;
  border: 1px solid $light-gray;
  border-radius: $radius;
  font-size: 12px;
  display: flex;
  height: 100px;
  .left-side {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    display: flex;
    position: relative;
    min-width: 25px;
    width: 25px;
    div {
      color: $white;
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      white-space: nowrap;
    }
  }
  .center-side {
    background: $white;
    border-right: 1px dashed $light-gray;
    padding: 10px;
    position: relative;
    min-width: calc(100% - 100px);
    width: calc(100% - 100px);
    .coupon-title {
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .coupon-content {
      position: absolute;
      bottom: 10px;
    }
    b {
      font-size: 16px;
    }
    small {
      font-size: 10px;
    }
  }
  .right-side {
    align-self: center;
    text-align: center;
    width: 100px;
    button {
      font-size: 12px;
      padding: 0;
      line-height: 1;
      height: 30px;
      width: 60px;
    }
  }
}
</style>