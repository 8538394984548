<template>
  <div class="coupon container mt-3">
    <div v-if="allCoupon && couponList.length > 0">
      <div class="mt-2" v-for="(i, index) in couponList" :key="index">
        <Coupon
          :item="i"
          :onClick="selectItem"
          :onClickShowDetail="openDetailModal"
        />
      </div>
    </div>
    <div v-if="allCoupon && couponList.length === 0">
      <div class="mt-2 text-center">ไม่พบข้อมูล</div>
    </div>
    <div v-if="myCoupon && generateList.length > 0">
      <div class="mt-2" v-for="(i, index) in generateList" :key="index">
        <Coupon
          :item="i"
          :onClick="openModal"
          :onClickShowDetail="openDetailModal"
        />
      </div>
    </div>
    <div v-if="myCoupon && generateList.length === 0">
      <div class="mt-2 text-center">ไม่พบข้อมูล</div>
    </div>
    <b-modal id="coupon_modal" hide-footer centered no-close-on-backdrop>
      <template v-slot:modal-header-close>
        <i
          class="close-modal icon-cancel-circled-outline"
          @click="clearFlag"
        ></i>
      </template>
      <div
        class="d-block mt-3 text-center"
        v-if="selectedCoupon && selectedCoupon.code"
      >
        <vue-qrcode
          :value="selectedCoupon.code"
          :options="{ width: 200, height: 200 }"
        />
      </div>
      <div
        class="d-block text-center row"
        v-if="selectedCoupon && selectedCoupon.code"
      >
        <VueBarcode :value="selectedCoupon.code" class="coupon-barcode" />
      </div>
      <!-- <div class="d-block text-center" v-if="selectedCoupon">
        <p class="coupon-code">{{ selectedCoupon.code }}</p>
      </div> -->
      <!-- <div class="text-center row" v-if="selectedCoupon">
        <div class="col">
          <button
            class="btn btn-outline-dark mt-5 w-100"
            @click="submitAction(id)"
            :disabled="flag"
            data-tag="coupon"
          >
            <span>เจ้าหน้าที่กดปุ่มนี้เท่านั้น</span>
          </button>
        </div>
      </div> -->
    </b-modal>
    <b-modal id="coupon_detail_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i
          class="close-modal icon-cancel-circled-outline"
          @click="closeDetailModal"
        ></i>
      </template>
      <div class="d-block mt-4" v-if="selectedCoupon">
        <img v-lazy="`${imagePath}${selectedCoupon.image}`" width="100%" />
        <div class="mt-3">
          <h5>{{ selectedCoupon.title }} {{ selectedCoupon.name }}</h5>
        </div>
        <!-- <div>{{ selectedCoupon.condition }}</div> -->
        <div
          v-if="selectedCoupon.condition"
          v-html="toHtml(selectedCoupon.condition)"
        ></div>
        <!-- <div class="row">
          <div class="col col-sm-6">
            <div><b>จำนวนสิทธิคงเหลือ</b></div>
            <div>{{ selectedCoupon.couponRedeemRemaining }}</div>
          </div>
          <div class="col col-sm-6"> -->
        <div><b>หมดเขต</b></div>
        <div>
          {{
            selectedCoupon.couponLifetimeType === "datetime"
              ? $getFullDate(selectedCoupon.couponEndDate)
              : $getFullDate(selectedCoupon.endDate)
          }}
        </div>
        <!-- </div>
        </div> -->
        <!-- <div><b>ข้อมูลเพิ่มเติม</b></div>
        <div>ดูรายละเอียดได้ที่ https://.com</div> -->
        <!-- <div><b>ข้อกำหนดและเงื่อนไข (Term & Conditions)</b></div>
        <div>ไม่สามารถใช้ร่วมกับโปรโมชั่นเสริมอื่นๆ</div> -->
      </div>
      <div v-if="allCoupon && selectedCoupon">
        <button
          v-if="
            selectedCoupon &&
            selectedCoupon.active === undefined &&
            selectedCoupon.couponRedeemRemaining === 0
          "
          disabled
          class="btn btn-primary mt-3 w-100"
          @click="selectItem(selectedCoupon)"
        >
          หมดแล้ว
        </button>
        <div v-else>
          <button
            v-if="
              (selectedCoupon &&
                selectedCoupon.active === undefined &&
                selectedCoupon.couponRedeemMaxUser > 0 &&
                selectedCoupon.couponRedeemRemainingUser > 0) ||
              (selectedCoupon &&
                selectedCoupon.active === undefined &&
                selectedCoupon.couponRedeemMaxUser === 0)
            "
            class="btn btn-primary mt-3 w-100"
            @click="selectItem(selectedCoupon)"
            data-tag="coupon"
          >
            <span>เก็บคูปอง</span>
          </button>
          <button
            v-if="
              selectedCoupon &&
              selectedCoupon.active === undefined &&
              selectedCoupon.couponRedeemMaxUser > 0 &&
              selectedCoupon.couponRedeemRemainingUser === 0 &&
              selectedCoupon.couponRedeemRemaining > 0
            "
            disabled
            class="btn btn-primary mt-3 w-100"
            @click="selectItem(selectedCoupon)"
          >
            เก็บแล้ว
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Coupon from "@/components/coupon";

import VueBarcode from "vue-barcode";
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  components: {
    Coupon,
    VueBarcode,
    VueQrcode,
  },
  computed: {
    imagePath() {
      return process.env.VUE_APP_IMAGE_PATH;
    },
    catalogList() {
      return this.$store.state.catalogList;
    },
    generateList() {
      const { name } = this.$route;
      switch (name) {
        case "my-coupon": {
          return this.activeCouponList;
        }
        case "use-coupon": {
          return this.inactiveCouponList;
        }
        default: {
          return [];
        }
      }
    },
  },
  data: () => ({
    couponList: [],
    activeCouponList: [],
    inactiveCouponList: [],
    allCoupon: false,
    myCoupon: false,
    selectedCoupon: null,
    flag: false,
  }),
  methods: {
    toHtml(val) {
      return val.replace(/\n/g, "<br>").replace(/\\n/g, "<br>");
    },
    async getPrivileges() {
      window.loading(true);
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/privileges/landing`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { result } = await res.json();
          const { catalogs } = result;
          this.$store.dispatch("setCatalog", catalogs);
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async getCatalogs(id) {
      window.loading(true);
      const formData = {
        catalogId: parseInt(id),
      };
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/privileges/catalog`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { result } = await res.json();
          const { privileges } = result;
          privileges.length > 0 &&
            privileges.forEach((i) => {
              this.couponList.push(i);
            });
          console.log(this.couponList);
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async redeemCoupon(id) {
      window.loading(true);
      const formData = {
        privilegeId: parseInt(id),
      };
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/coupons/takeCoupon`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { error } = await res.json();
          switch (error) {
            case null:
              this.Swal.fire("เก็บคูปองสำเร็จ", "", "success").then(() => {
                this.$router.go(0);
              });
              break;
            default:
              this.Swal.fire(
                "เกิดข้อผิดพลาด",
                "กรุณาติดต่อเจ้าหน้าที่",
                "error"
              );
              break;
          }
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async getMyCoupon() {
      this.activeCouponList = [];
      window.loading(true);
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/coupons/myCoupon`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { result } = await res.json();
          const { list } = result;
          list.forEach((i) => {
            this.activeCouponList.push({
              ...i.privilege,
              active: true,
              couponId: i.id,
            });
          });
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async getMyCouponHistory() {
      this.inactiveCouponList = [];
      window.loading(true);
      const res = await fetch(
        `${process.env.VUE_APP_API_URL}/coupons/history`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      );
      const { status } = res;
      switch (status) {
        case 200: {
          const { result } = await res.json();
          const { list } = result;
          list.forEach((i) => {
            this.inactiveCouponList.push({
              ...i.privilege,
              active: false,
              couponId: i.id,
              expired: i.expired,
              used: i.used,
            });
          });
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async getCouponCode(id) {
      window.loading(true);
      const formData = {
        couponId: parseInt(id),
      };
      const res = await fetch(`${process.env.VUE_APP_API_URL}/coupons`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const { status } = res;
      switch (status) {
        case 200: {
          const { result } = await res.json();
          const { code } = result;
          this.selectedCoupon = {
            id,
            code: code.replace(/[^A-Z0-9]/gi, ""),
          };
          window.loading(false);
          break;
        }
        default:
          window.loading(false);
          break;
      }
    },
    async selectItem(item) {
      await this.redeemCoupon(item.id);
      this.closeDetailModal();
    },
    async openModal(item) {
      await this.getCouponCode(item.couponId);
      this.$bvModal.show("coupon_modal");
    },
    openDetailModal(item) {
      this.selectedCoupon = item;
      this.$bvModal.show("coupon_detail_modal");
    },
    submitAction(id) {
      console.log(id);
      this.selectedCoupon = null;
      this.flag = true;
    },
    closeDetailModal() {
      this.selectedCoupon = null;
      this.$bvModal.hide("coupon_detail_modal");
    },
    clearFlag() {
      this.selectedCoupon = null;
      this.flag = false;
    },
  },
  async mounted() {
    const { name } = this.$route;
    this.catalogList.length === 0 && (await this.getPrivileges());
    switch (name) {
      case "all-coupon": {
        this.allCoupon = true;
        this.catalogList.length > 0 &&
          (await this.catalogList.forEach((i) => {
            this.getCatalogs(i.id);
          }));
        break;
      }
      case "my-coupon": {
        this.myCoupon = true;
        await this.getMyCoupon();
        break;
      }
      case "use-coupon": {
        this.myCoupon = true;
        await this.getMyCouponHistory();
        break;
      }
      default: {
        console.log("default case");
        break;
      }
    }
  },
  watch: {
    async $route(to, from) {
      if (from !== to) {
        switch (to.name) {
          case "my-coupon": {
            this.myCoupon = true;
            await this.getMyCoupon();
            break;
          }
          case "use-coupon": {
            this.myCoupon = true;
            await this.getMyCouponHistory();
            break;
          }
          default: {
            console.log("default case");
            break;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-code {
  letter-spacing: 5px;
}
.coupon-barcode {
  display: flex;
  justify-content: center;
}
</style>